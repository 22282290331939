import { Country, CountryCode, Currency, CurrencyCode } from '@/shared/types';

/** 30 minutes */
export const defaultStaleTime = 30 * 60 * 1000;

// local storage keys
export const STORAGE_KEYS = {
  CLIENT_TOKEN_STORAGE_KEY: 'fx.remittance.token',
};

/** A collection of countries */
export const countryCollection: Record<CountryCode, Country> = {
  GB: {
    code: 'GB',
    callingCode: '44',
    currencyCode: 'GBP',
    name: 'United Kingdom',
    bankCodeLength: {
      min: 6,
      max: 6,
    },
    backAccountNumberLength: {
      min: 8,
      max: 8,
    },
  },
  NG: {
    code: 'NG',
    name: 'Nigeria',
    callingCode: '234',
    currencyCode: 'NGN',
    bankCodeLength: {
      min: 3,
      max: 3,
    },
    backAccountNumberLength: {
      min: 10,
      max: 10,
    },
  },
};

/** A collection of currencies */
export const currencyCollection: Record<CurrencyCode, Currency> = {
  GBP: {
    code: 'GBP',
    symbol: '£',
    countryCode: 'GB',
    name: 'Pound',
  },
  NGN: {
    code: 'NGN',
    symbol: '₦',
    countryCode: 'NG',
    name: 'Naira',
  },
};
