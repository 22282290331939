import { unstable_noStore as noStore } from 'next/cache';
import { runtimeConfig } from './runtime-config';

export function RuntimeConfigScript() {
  noStore();

  return (
    <script
      id="env-config"
      dangerouslySetInnerHTML={{
        __html: `
        window.inject = window.inject || {};
        window.inject.CONFIG = ${JSON.stringify(runtimeConfig)};
      `,
      }}
    />
  );
}
