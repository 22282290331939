export function ZendeskConfigScript() {
  return (
    // Start of teamapt1681922606 Zendesk Widget script
    <script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=0c0bc6b0-1014-4f9c-84b3-c0f989fb4aba"
      //   src={`https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_KEY}`}
      async={true}
    />
    // End of teamapt1681922606 Zendesk Widget script
  );
}
