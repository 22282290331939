interface Config {
  [key: string]: string;
}

const getPublicEnvVariables = (): Config => {
  const config: Config = {};

  for (const key in process.env) {
    if (key.startsWith('NEXT_PUBLIC_')) {
      const strippedKey = key.replace(/^NEXT_PUBLIC_/, ''); // Remove the prefix
      config[strippedKey] = process.env[key] || '';
    }
  }
  
  return config;
};

export const runtimeConfig: Config = getPublicEnvVariables();
